import React from "react";
import { graphql, Link } from "gatsby";

import { container, title, actionBar } from "./html-error-message.module.scss";

import MainLayout from "../../layouts/main-layout";
import Title from "../atoms/title";
import Button from "../atoms/button";
import Section from "../molecules/section";

interface IErrorPageContext {
    statusCode?: number;
    message?: string;
    seoMessage?: string;
}

interface HtmlErrorMessageProps {
    pageContext: IErrorPageContext;
}

const HtmlErrorMessage = ({ pageContext }: HtmlErrorMessageProps) => {
    const { statusCode, message, seoMessage } = pageContext;

    return (
        <MainLayout SEOProps={{ title: seoMessage }}>
            <Section
                column={"narrow"}
                columnTablet={"regular"}
                columnPhone={"regular"}
                className={container}
            >
                <Title align={"center"} className={title}>
                    Kod błędu: {statusCode}
                </Title>
                <p>{message ? message : "Wystąpił problem"}</p>
                <div className={actionBar}>
                    <Link to="/">
                        <Button type={"filled"}>Strona główna</Button>
                    </Link>
                    <Link to="/kontakt/">
                        <Button type={"filled"}>Zgłoś błąd</Button>
                    </Link>
                </div>
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default HtmlErrorMessage;
